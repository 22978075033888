import vue from "vue";
import Router from "vue-router";
import store from "@/store";
import Cookies from "js-cookie";
import { getInfo } from "@/api/login";
// 处理高版本路由报错
const originalPush = Router.prototype.push;
Router.prototype.push = function push(location, onResolve, onReject) {
  if (onResolve || onReject)
    return originalPush.call(this, location, onResolve, onReject);
  return originalPush.call(this, location).catch((err) => err);
};
vue.use(Router);
// 使用路由懒加载的方式加载路由
const cart = () => import("./pages/cart.vue");
const detail = () => import("./pages/detail.vue");
const home = () => import("./pages/home.vue");
const index = () => import("./pages/index.vue");
const login = () => import("./pages/login.vue");
const WXLogin = () => import("./pages/WXLogin.vue");
const BindPhone = () => import("./pages/BindPhone.vue");
const order = () => import("./pages/order.vue");
const orderConfrim = () => import("./pages/orderConfrim.vue");
const orderList = () => import("./pages/orderList.vue");
const orderPay = () => import("./pages/orderPay.vue");
// const product = () => import("./pages/product.vue");
const publicity = () => import("./pages/publicity.vue");
const filterList = () => import("./pages/filterList.vue");
const alipay = () => import("./pages/alipay.vue");
const accountCenter = () => import("./pages/AccountCenter/index");
const WelcomePage = () => import("./pages/AccountCenter/WelcomePage");
const Statistics = () => import("./pages/AccountCenter/Statistics");
// const PersonalInformation = () => import('./pages/AccountCenter/PersonalInformation')
// const PersonalInformationEdit = () => import('./pages/AccountCenter/PersonalInformation/EditPersonalInformation')
const CompanyInformation = () =>
  import("./pages/AccountCenter/CompanyInformation");
const EditCompanyInformation = () =>
  import("./pages/AccountCenter/CompanyInformation/EditCompanyInformation");
const MyProduct = () => import("./pages/AccountCenter/MyProduct");
const PublishAndEditProduct = () =>
  import("./pages/AccountCenter/MyProduct/PublishAndEditProduct");
// const MyNeeds = () => import('./pages/AccountCenter/MySupplyAndDemand/MyNeeds')
// const MyOffer = () => import('./pages/AccountCenter/MySupplyAndDemand/MyOffer')
// const FavoriteBusiness = () => import('./pages/AccountCenter/MyCollection/FavoriteBusiness')
// const FavoriteProducts = () => import('./pages/AccountCenter/MyCollection/FavoriteProducts')
// const MyTransaction = () => import('./pages/AccountCenter/MyTransaction')
const TrialApplication = () => import("./pages/AccountCenter/TrialApplication");
// const SignedAgreement = () => import('./pages/AccountCenter/SignedAgreement')
// const HistoryNotification = () => import('./pages/AccountCenter/HistoryNotification')
const medical = () => import("./pages/medical");
const productMobile = () => import("./pages/mobile/product");
const detailMobile = () => import("./pages/mobile/detail");
const Information = () => import("./pages/information");
const InformationDetails = () =>
  import("./pages/information/informationDetails");

const router = new Router({
  mode: "hash",
  routes: [
    {
      path: "/",
      component: () => import("@/pages/new/index.vue"),
      redirect: "/new/home",
      children: [
        {
          path: "/new/home",
          name: "home",
          component: () => import("@/pages/new/home/index.vue"),
        },
        {
          path: "/new/community",
          name: "community",
          component: () => import("@/pages/new/community/index.vue"),
        },
        {
          path: "/new/community/detail/:id",
          name: "community-detail",
          component: () => import("@/pages/new/community/detail/index.vue"),
        },
        {
            path: "/new/infoNews/:id",
            name: "home-detail",
            component: () => import("@/pages/new/home/detail/index.vue"),

        },

        {
          path: "/new/all-version/",
          name: "all-version",
          component: () => import("@/pages/new/home/version/index.vue"),

        },
        {
          path: "/new/cooperation",
          name: "cooperation",
          component: () => import("@/pages/new/cooperation/index.vue"),
        },
      ],
    },
    {
      path: "/mobile",
      component: () => import("@/pages/mobile/new/index.vue"),
      redirect: "/mobile/new/home",
      children: [
        {
          path: "/mobile/new/home",
          name: "mobile-home",
          component: () => import("@/pages/mobile/new/home/index.vue"),
        },
        {
          path: "/mobile/new/community",
          name: "mobile-community",
          component: () => import("@/pages/mobile/new/community/index.vue"),
        },
        {
          path: "/mobile/new/community/detail/:id",
          name: "mobile-community-detail",
          component: () => import("@/pages/mobile/new/community/detail/index.vue"),
        },
      ],
    },
    // 初始默认路由(home)
    {
      path: "",
      name: "",
      component: home,
      children: [
        // 商品详情（动态）
        /*{
          path: "product",
          name: "product",
          component: product,
        },*/
        // 大图展示（动态）
        {
          path: "detail/:id",
          name: "detail",
          component: detail,
        },
        // 首页
        {
          path: "index",
          name: "index",
          component: index,
        },
        //  宣传页
        {
          path: "publicity",
          name: "publicity",
          component: publicity,
        },
        //  筛选页
        {
          path: "filterList",
          name: "filterList",
          component: filterList,
        },
        {
          path: "/information",
          name: "information",
          meta: {
            hideFooter: true,
            keepAlive: true,
            isBack: false,
          },
          component: Information,
        },
        {
          path: "/information-details",
          name: "informationDetails",
          meta: {
            hideFooter: true,
          },
          component: InformationDetails,
        },
      ],
    },
    // 购物车路由
    {
      path: "/cart",
      name: "cart",
      component: cart,
    },
    // order父路由
    {
      path: "/order",
      name: "order",
      component: order,
      children: [
        // 订单列表
        {
          path: "List",
          name: "orderList",
          component: orderList,
        },
        // 订单确认
        {
          path: "Confrim",
          name: "orderConfrim",
          component: orderConfrim,
        },
        // 订单支付
        {
          path: "Pay",
          name: "orderPay",
          component: orderPay,
        },
        {
          path: "alipay",
          name: "alipay",
          component: alipay,
        },
      ],
    },
    {
      path: "/account",
      name: "account",
      component: accountCenter,
      redirect: "/account/information",
      children: [


        // 欢迎页
        {
          path: "/account/welcome-page",
          name: "WelcomePage",
          component: WelcomePage,
          meta: {
            title: "欢迎页",
          },
        },
        // 个人信息
        // {
        //   path: '/account/personal-information',
        //   name: 'PersonalInformation',
        //   component: {render: h => h('router-view')},
        //   redirect: '/account/personal-information/info',
        //   meta: {
        //     title: "个人信息",
        //     hideChildren: true
        //   },
        //   children: [
        //     {
        //       path: '/account/personal-information/info',
        //       name: 'PersonalInformationInfo',
        //       component: PersonalInformation,
        //       meta: {
        //         title: '个人信息'
        //       }
        //     },
        //     {
        //       path: '/account/personal-information/edit',
        //       name: 'PersonalInformationEdit',
        //       component: PersonalInformationEdit,
        //       meta: {
        //         title: '个人信息-修改信息'
        //       }
        //     },
        //   ]
        // },
        // 公司信息
        // 信息维护
        {
          path: "/account/information",
          name: "Information",
          component: { render: (h) => h("router-view") },
          redirect: "/account/information/show",
          meta: {
            title: "信息维护",
            hideChildren: true,
          },
          children: [
            {
              path: "/account/information/show",
              name: "showInformation",
              component: CompanyInformation,
              meta: {
                title: "信息维护",
              },
            },
            {
              path: "/account/information/edit",
              name: "editInformation",
              component: EditCompanyInformation,
              meta: {
                title: "信息维护-修改信息",
              },
            },
          ],
        },

        // 我的产品
        {
          path: "/account/my-product",
          name: "MyProduct",
          component: { render: (h) => h("router-view") },
          redirect: "/account/my-product/info",
          meta: {
            title: "我的产品",
            hideChildren: true,
          },
          children: [
            {
              path: "/account/my-product/info",
              name: "MyProductInfo",
              component: MyProduct,
              meta: {
                title: "我的产品",
              },
            },
            {
              path: "/account/my-product/publish/:id",
              name: "MyProductPublish",
              component: PublishAndEditProduct,
              meta: {
                title: "我的产品-发布产品",
              },
            },
          ],
        },
        // 我的供需
        // {
        //   path: '/account/my-supply-and-demand',
        //   name: 'MySupplyAndDemand',
        //   component: {render: h => h("router-view")},
        //   redirect: "/account/my-supply-and-demand/my-needs",
        //   meta: {
        //     title: "我的供需"
        //   },
        //   children: [
        //     {
        //       path: '/account/my-supply-and-demand/my-needs',
        //       name: 'MyNeeds',
        //       component: MyNeeds,
        //       meta: {
        //         title: "我的需求"
        //       },
        //     },
        //     {
        //       path: '/account/my-supply-and-demand/my-offer',
        //       name: 'MyOffer',
        //       component: MyOffer,
        //       meta: {
        //         title: "我能提供"
        //       },
        //     }
        //   ]
        // },
        // 我的收藏
        // {
        //   path: '/account/my-collection',
        //   name: 'MyCollection',
        //   component: {render: h => h("router-view")},
        //   redirect: "/account/my-collection/favorite-business",
        //   meta: {
        //     title: "我的收藏"
        //   },
        //   children: [
        //     {
        //       path: '/account/my-collection/favorite-business',
        //       name: 'FavoriteBusiness',
        //       component: FavoriteBusiness,
        //       meta: {
        //         title: "收藏的企业"
        //       },
        //     },
        //     {
        //       path: '/account/my-collection/favorite-products',
        //       name: 'FavoriteProducts',
        //       component: FavoriteProducts,
        //       meta: {
        //         title: "收藏的产品"
        //       },
        //     }
        //   ]
        // },
        // 我的交易
        // {
        //   path: '/account/my-transaction',
        //   name: 'MyTransaction',
        //   component: MyTransaction,
        //   meta: {
        //     title: "我的交易"
        //   }
        // },
        // 试用申请
        {
          path: "/account/trial-application",
          name: "TrialApplication",
          component: TrialApplication,
          meta: {
            title: "用户申请",
          },
        },
        //  数据统计 Statistics
        {
          path: "/account/statistics",
          name: "Statistics",
          component: Statistics,
          meta: {
            title: "数据统计",
          },
        },
        // 签署的协议
        // {
        //   path: '/account/signed-agreement',
        //   name: 'SignedAgreement',
        //   component: SignedAgreement,
        //   meta: {
        //     title: "签署的协议"
        //   }
        // },
        // 历史通知
        // {
        //   path: '/account/history-notification',
        //   name: 'HistoryNotification',
        //   component: HistoryNotification,
        //   meta: {
        //     title: "历史通知"
        //   }
        // },
      ],
    },
    {
      path: "/login",
      name: "login",
      component: login,
    },
    {
      path: "/WXLogin",
      name: "WXLogin",
      component: WXLogin,
    },
    {
      path: "/bind-phone",
      name: "BindPhone",
      component: BindPhone,
    },
    {
      path: "/medical",
      name: "medical",
      component: medical,
    },
    {
      path: "/product-mobile",
      name: "productMobile",
      component: productMobile,
    },
    {
      path: "/detail-mobile/:id",
      name: "detailMobile",
      component: detailMobile,
    },
      //用户协议
    {
      path: "/userAgreement",
      component: () => import("@/pages/protocol/userAgreement.vue"),
    },
      //隐私政策
    {
      path: "/privacyPolicy",
      component: () => import("@/pages/protocol/privacyPolicy.vue"),
    },
  ],
  scrollBehavior(to) {
    if (to.hash) {
      return { selector: to.hash, behavior: "smooth" };
    }
    return {
      x: 0,
      y: 0,
      behavior: "smooth",
    };
  },
});

router.beforeEach(async (to, from, next) => {
  if( sessionStorage.getItem("isFirst")=== null){
    sessionStorage.setItem("logidUrl",window.location.href);
    sessionStorage.setItem("isFirst","false");
  }
  console.log(1111,sessionStorage.getItem("logidUrl",window.location.href))
  const access_token = Cookies.get("authorized-token");
  if (!store.getters["user/getUserInfo"] && access_token) {
    localStorage.setItem("token", access_token);
    store.commit("user/setToken", access_token);
    getInfo().then((res) => {
      if (res.code === 200) {
        store.commit("user/updateUserInfo", res.data);
        localStorage.setItem("userInfo", JSON.stringify(res.data));
      }
    });
  }
  if (to.path) {
    if (window._hmt) {
      window._hmt.push(["_trackPageview", "/#" + to.fullPath]);
    }
  }
  next();

});

export default router;
