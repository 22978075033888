import Vue from 'vue'
import vuex from 'vuex'
import createPersistedState from 'vuex-persistedstate'
import actions from './action'
import mutations from './mutations'
import user from './modules/user'
Vue.use(vuex)
// 状态管理
let state={
  username:'',
  cartnum:0,
  isshow:1,
}
export default new vuex.Store({
  modules: {
    user
  },
  plugins: [createPersistedState({
    storage: window.sessionStorage,
    reducer(val) {
        return {
          user: val.user
        }
      }
    })
  ],
  state,
  actions,
  mutations
})
