import axios from "axios";
import { Message } from "element-ui";
import store from "../store";
import Cookies from "js-cookie";

axios.defaults.headers["Content-Type"] = "application/json;charset=ut";

axios.defaults.validateStatus = function (status) {
  return status >= 200 && status <= 500; // 默认的
};

// 创建axios实例
const service = axios.create({
  // baseURL: process.env.VUE_APP_API_URL,
  headers: { "Cache-Control": "no-cache" },
  // 超时
  timeout: 60000,
});

// request拦截器
service.interceptors.request.use(
  (config) => {
    const isToken = (config.headers || {}).isToken === false;

    let token = localStorage.getItem("token") || "";
    let authorizedToken = Cookies.get("authorized-token") || "";
    let type = localStorage.getItem("type") || 2;
    if (!authorizedToken || (authorizedToken && authorizedToken !== token)) {
      store.commit("user/setToken", "");
      store.commit("user/updateUserInfo", "");
      localStorage.removeItem("token");
    }
    if (token && !isToken) {
      config.headers["Authorization"] = "Bearer " + token;
    }
    if (
      config.url.indexOf("/oauth/token") !== -1 ||
      config.url.indexOf("/sms/send/1") !== -1
    ) {
      config.headers["Authorization"] = "Basic dG50bGlua2luZzptYW5wb3dlcg==";
    }
    config.headers["type"] = type;
    return config;
  },
  (error) => {
    Promise.reject(error);
  },
);

// 响应拦截器
service.interceptors.response.use(
  (res) => {
    const status = Number(res.status) || 200;
    const msg = res.data.msg || res.data.message || res.message;
    const errorMsg = res.data.error_description || "";
    if (status === 401) {
      localStorage.clear();
      store.state.user.token = "";
      store.state.user.userInfo = {};
      let path = window.location.hash;
      if (path.includes("/account")) {
        window.location.href = "#/new/home";
      }
    } else if (status === 400) {
      if (errorMsg) {
        Message.error(errorMsg);
        return Promise.reject(new Error(errorMsg));
      } else {
        Message.error(msg);
        return Promise.reject(new Error(msg));
      }
    } else if (status === 500) {
      Message.error(msg);
      return Promise.reject(new Error(msg));
    } else if (status === 409) {
      Message.error(msg);
      return Promise.reject(new Error(msg));
    } else if (status !== 200 || res.data.code === 1) {
      Message.error(msg);
      return Promise.reject(new Error(msg));
    } else if (res.headers["content-disposition"]) {
      return res;
    } else {
      const code = Number(res.data.code) || 200;
      if (code !== 200) {
        if (code === 401) {
          localStorage.clear();
          store.state.user.token = "";
          store.state.user.userInfo = {};
          let path = window.location.hash;
          if (path.includes("/account")) {
            window.location.href = "#/new/home";
          }
          // else {
          //     Message.info('请重新登录')
          //     // location.reload()
          // }
          // window.location.href = '#/login'
          Message.warning(res.data.message);
        } else {
          Message.error(res.data.message);
          return Promise.reject(new Error(res.data.message));
        }
      } else {
        return res.data;
      }
    }
  },
  (error) => {
    let { msg } = error;
    if (msg == "Network Error") {
      msg = "后端接口连接异常";
    } else if (msg.includes("timeout")) {
      msg = "系统接口请求超时";
    } else if (msg.includes("Request failed with status code")) {
      msg = "系统接口" + msg.substr(msg.length - 3) + "异常";
    }
    Message.error({
      content: msg,
      duration: 5 * 1000,
    });
    return Promise.reject(new Error(error));
  },
);

export default service;
