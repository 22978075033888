export default {
  // 保存用户名
  setUsername(stage,username){
    stage.username = username
  },
  // 保存购物车数量
  setCartnum(stage,cartnum){
    stage.cartnum=cartnum
  },
  setIsShow(stage,ishow){
    stage.isshow=ishow
  }
}
