import {
  Input,
  Form,
  FormItem,
  Button,
  Progress,
  Card,
  Row,
  Col,
  Select,
  Option,
  Tabs,
  TabPane,
  Pagination,
  Skeleton,
  SkeletonItem,
  Icon,
  Breadcrumb,
  BreadcrumbItem,
  Dropdown,
  DropdownMenu,
  DropdownItem,
  Tag,
  Dialog,
  Switch,
  Upload,
  Popover,
} from "element-ui";

const element = {
  install: function (Vue) {
    Vue.use(Input);
    Vue.use(Form);
    Vue.use(FormItem);
    Vue.use(Button);
    Vue.use(Progress);
    Vue.use(Card);
    Vue.use(Row);
    Vue.use(Col);
    Vue.use(Select);
    Vue.use(Option);
    Vue.use(Tabs);
    Vue.use(TabPane);
    Vue.use(Pagination);
    Vue.use(Skeleton);
    Vue.use(SkeletonItem);
    Vue.use(Icon);
    Vue.use(Breadcrumb);
    Vue.use(BreadcrumbItem);
    Vue.use(Dropdown);
    Vue.use(DropdownMenu);
    Vue.use(DropdownItem);
    Vue.use(Tag);
    Vue.use(Dialog);
    Vue.use(Switch);
    Vue.use(Upload);
    Vue.use(Popover);
  },
};

export default element;
