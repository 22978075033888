import request from "@/util/request";

// 登录
export const login = data => {
    // return request({
    //     url: "/new_gateway/tntlinking-sso-authcenter/oauth/token",
    //     headers: { "Authorization": 'Basic dG50bGlua2luZzptYW5wb3dlcg==' },
    //     method: "post",
    //     params: data
    // });
    
    return request({
      url: "/new_gateway/codepower-rest-api/user/login",
      headers: { "Authorization": 'Basic dG50bGlua2luZzptYW5wb3dlcg==' },
      method: "post",
      params: data
  });
};

// 退出 logout
export const loginOut = data => {
    return request({
        url: "/new_gateway/codepower-rest-api/user/logout",
        method: "post",
        params: data
    });
};

// 获取验证码
export const code = data => {
    // return request({
    //     url: "/new_gateway/tntlinking-sso-authcenter/sms/send/1?mobile="+data.mobile,
    //     headers: { "Authorization": 'Basic dG50bGlua2luZzptYW5wb3dlcg==' },
    //     method: "get",
    // });
    return request({
      url: "/new_gateway/codepower-rest-api/user/sendCode?mobile="+data.mobile,
      headers: { "Authorization": 'Basic dG50bGlua2luZzptYW5wb3dlcg==' },
      method: "get",
  });
};

// 获取用户信息
export const getInfo = () => {
  return request({
      url: "/new_gateway/codepower-rest-api/user/getInfo",
      // headers: { "Authorization": 'Basic dG50bGlua2luZzptYW5wb3dlcg==' },
      method: "get",
  });
};

// 获取场景二维码
export const getWechatQrcode = data => {
  return request({
    url: "/jump/wechat/get/qrcode",
    method: "get",
    params: data
  });
};

// 轮询获取微信公众号的信息
export const getWechatToken = data => {
  return request({
    url: "/new_gateway/tntlinking-sso-authcenter/wechat/get/access/token",
    method: "get",
    params: data
  });
};
