import Vue from 'vue'
import router from "./router"
import axios from 'axios'
import VueAxios from 'vue-axios'
import VueLazyload from "vue-lazyload"
import VueCookie from 'vue-cookie'
import store from './store/index'
import { Message } from "element-ui";
import './assets/scss/app.scss'
import 'element-ui/lib/theme-chalk/index.css'
import element from './element/index'
import App from './App.vue'
import 'font-awesome/css/font-awesome.min.css'

// 引入iconfont
import './assets/font/iconfont.css'
import './assets/fontnew/iconfont.css'

import Distpicker from 'v-distpicker'
Vue.component('v-distpicker', Distpicker)

import VueClipboard from "vue-clipboard2"
VueClipboard.config.autoSetContainer = true // add this line
Vue.use(VueClipboard);

// 按需加载element组件
Vue.prototype.$message = Message;


// Vue.use(Antd);
Vue.use(element)

//将axios挂载在Vue实例上，之后就可使用this调用axios实例。
Vue.use(VueAxios, axios);
//挂载lazyload插件,并配置loading
Vue.use(VueLazyload, {
  loading: '/imgs/loading-svg/loading-bars.svg'
})
// 挂载vue-Cookie插件
Vue.use(VueCookie);
Vue.config.productionTip = false
new Vue({
  router,
  store,
  render: h => h(App),
  beforeCreate() {
    Vue.prototype.$Bus = this
  }
}).$mount('#app')
