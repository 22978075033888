import Cookies from "js-cookie";

const state = {
  token: "",
  userInfo: {},
};

const getters = {

  getUserInfo: (state) => {
    if(state.token == "" || state.token == undefined || state.token.length == 0){
        state.userInfo = JSON.parse(localStorage.getItem("userInfo"));
    }
    return state.userInfo;
  }
};

const mutations = {
  setToken(state, token) {
    Cookies.set("authorized-token", token);
    state.token = token;
  },
  updateUserInfo(state, user) {
    state.userInfo = user;
    localStorage.setItem("userInfo", JSON.stringify(user));
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
};
